import { trainingTypesTranslations, TRAINING_TYPES } from './trainingTypes.en';

const FOOD_DURING_ACTIVITY = 'Food during your activity';

export default {
  activityLevel: 'Activity level',
  activityLevelOnboarding: 'How active are you besides sports and working hours?',
  activityLevels: {
    light: 'Average active',
    moderate: 'Quite active',
    heavy: 'Very active',
  },
  chooseRecipeMoments: 'What other moments would this meal be suitable for?',
  chooseFrom: 'Meal moments',
  chooseRecipeName: 'Give this meal a name',
  choseYourSport: 'Choose your team sport',
  date: 'Date',
  dateOfBirth: 'Date of birth',
  energyRequirement: 'Macronutrients',
  dayOfWeek: {
    Mo: 'Mo',
    Tu: 'Tu',
    We: 'We',
    Th: 'Th',
    Fr: 'Fr',
    Sa: 'Sa',
    Su: 'Su',
  },
  matchingNutrients: {
    title: 'This is a perfect fit!',
    subtext:
      'This meal is matched to your energy needs. You’re on your way to reach your daily needs.',
  },
  notMatchingNutrients: {
    title: 'Great choice! Note that not all macronutrients are within your ranges',
    subtext:
      'This meal is matched to your energy needs. Some macro’s for this moment are not 100% perfect. You can focus on these macro’s in the next meals to reach your daily target.',
  },
  doesMatchMealPlan: 'Perfect fit',
  myRecipe: 'My recipe',
  myIngredient: 'My product',
  doesNotMatchMealPlan: 'Does not meet your plan',
  ftpValue: 'Your estimated FTP',
  highlightMoments: {
    preWorkoutMeal: 'Pre-workout meal',
    preWorkoutSnack: 'Pre-workout snack',
    recoveryMeal: 'Recovery meal',
    recoverySnack: 'Shake',
  },
  hockey: {
    matchDayKeeper: 'Match (goalkeeper)',
    matchDayPlayer: 'Match (field player)',
    normalTraining: 'Normal training',
    intensiveTraining: 'Intensive training',
    keeperTraining: 'Goalkeepr training',
  },
  showPreparedAmounts: 'Show prepared amounts',
  fitness: {
    strength: TRAINING_TYPES.STRENGTH_TRAINING,
    cardioGroup: TRAINING_TYPES.CARDIO_GROUP_TRAINING,
    cardioStrength: TRAINING_TYPES.CARDIO_STRENGTH_TRAINING,
    circuitCrossfit: TRAINING_TYPES.CIRCUIT_CROSSFIT_TRAINING,
    cardioStrengthEasy: 'Easy',
    cardioStrengthModerate: 'Average',
    cardioStrengthIntensive: 'Intensive',
    strengthLotsResistance: 'Low resistance',
    strengthLittleResistance: 'High resistance',
    littleRest: '0-120 sec',
    lotsRest: '> 120 sec',
    cardioGroupLessonEasy: 'Easy',
    cardioGroupLessonModerate: 'Average',
    cardioGroupLessonIntensive: 'Intensive',
    circuitCrossfitModerate: 'Average',
    circuitCrossfitIntensive: 'Intensive',
  },
  hockeyDuration: 'How long will your training take?',
  hockeyMoment: 'I will train before',
  hours: 'Hours',
  intensity: 'Trainingintensity',
  intensityType: 'Trainingintensity',
  restType: 'Rest between sets',
  length: 'Length',
  mealDifficulties: {
    simple: 'simple',
    medium: 'medium',
    hard: 'hard',
  },
  moments: {
    afternoonSnack: 'Afternoon snack',
    beforeAfternoonSnack: FOOD_DURING_ACTIVITY,
    beforeBreakfast: FOOD_DURING_ACTIVITY,
    beforeDinner: FOOD_DURING_ACTIVITY,
    beforeEveningSnack: FOOD_DURING_ACTIVITY,
    beforeHalfTime: FOOD_DURING_ACTIVITY,
    beforeLunch: FOOD_DURING_ACTIVITY,
    beforeMorningSnack: FOOD_DURING_ACTIVITY,
    beforeRecoverySnack: FOOD_DURING_ACTIVITY,
    beforeShake: FOOD_DURING_ACTIVITY,
    breakfast: 'Breakfast',
    cherryJuice: 'Cherry juice',
    dinner: 'Dinner',
    eveningSnack: 'Evening snack',
    gel: 'Gel',
    halfTime: 'Half-time',
    lunch: 'Lunch',
    morningSnack: 'Morning snack',
    postGameMeal: 'Post-game meal',
    preGame: 'Pre-game',
    preGameMeal: 'Pre-game meal',
    preRaceMeal: 'Pre-race meal',
    preRaceSnack: 'Pre-race snack',
    preWarmingUp: 'Pre-warming-up',
    recoveryMeal1: 'Recovery meal 1',
    recoveryMeal2: 'Recovery meal 2',
    recoveryMeal: 'Recovery meal',
    recoverySnack: 'Shake',
    shake: 'Shake',
    snack: 'Snack',
  },
  minimumIntake: 'Minimum energy needs:',
  notAvailable: 'Not available yet',
  nutrientPer100OfUnit: '{nutrient} per 100 {unit}',
  nutrients: {
    calories: 'Energy',
    carbs: 'Carbs',
    fat: 'Fats',
    fiber: 'Fibres',
    protein: 'Protein',
  },
  nutritionalValues: 'Nutritional values',
  running: {
    distance: 'Distance',
    tempo: 'Pace (min/km)',
  },
  recipeTypes: {
    breakfast: 'Breakfast',
    lunch: 'Lunch',
    dinner: 'Dinner',
    snack: 'Snack',
  },
  searchRecipes: 'What do you want to eat',
  sleepingHours: 'Sleeping hours',
  soccerDuration: 'How long will your training take?',
  products: 'Products',
  recipes: 'Recipes',
  tunedRecipes: 'Tuned recipes',
  sports: {
    cycling: 'Cycling',
    fitness: 'Fitness',
    running: 'Running',
    teamsport: 'Teamsport',
    hockey: 'Hockey',
    soccer: 'Football',
  },
  tileFilters: {
    all: 'Show everyting',
    vegetarian: 'Vegetarian',
  },
  today: 'Today',
  activityTrainingDuration: 'Planned duration',
  activityActualDuration: 'Actual duration',
  trainingDuration: 'How long will your training take?',
  actualDuration: 'How long did you train?',
  trainingMoment: 'I will train before',
  trainingMoments: {
    morning: 'Morning',
    afternoon: 'Afternoon',
    evening: 'Evening',
    beforeBreakfast: 'Breakfast',
    beforeMorningSnack: 'Morning snack',
    beforeLunch: 'Lunch',
    beforeAfternoonSnack: 'Afternoon snack',
    beforeRecoverySnack: 'Shake',
    beforeDinner: 'Dinner',
    beforeEveningSnack: 'Evening snack',
  },
  trainingTypes: {
    ...trainingTypesTranslations(),
  },
  trainingType: 'Training type',
  trainingMainType: 'Training type',
  intensityTypes: {
    strengthLotsResistanceLittleRest: 'Low resistance',
    strengthLotsResistanceLotsRest: 'Low resistance',
    strengthLittleResistanceLittleRest: 'High resistance',
    strengthLittleResistanceLotsRest: 'High resistance',
    cardioStrengthEasy: 'Easy',
    cardioStrengthModerate: 'Average',
    cardioStrengthIntensive: 'Intensive',
    cardioGroupLessonEasy: 'Rustig',
    cardioGroupLessonModerate: 'Average',
    cardioGroupLessonIntensive: 'Intensive',
    circuitCrossfitModerate: 'Average',
    circuitCrossfitIntensive: 'Intensive',
  },
  units: {
    gram: 'Gram',
    milliliter: 'Milliliter',
  },
  unitOfMeasure: 'Unit of measure',
  weight: 'Weight',
  workingHours: 'Working hours',
  workType: 'Work type',
  whichTeamSport: 'Choose your teamsport',
  workTypes: {
    unemployed: 'I don’t work',
    sedentary: 'Physically passive (sitting down)',
    light: 'Mildly active',
    moderate: 'Quite active',
    heavy: 'Very active',
  },
  yourWeight: 'Weight',
  ingredientName: 'Give this product a name',
  ingredientNameAlreadyExists: 'This product already exists, please choose another name',
};

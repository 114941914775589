export default {
  preparedAmountsExplanation: [
    {
      text: 'By using this toggle, you can display the prepared amounts. FoodCoach will then apply the cooking factor of a specific ingredient to determine how much you need to scoop from the pan.',
    },
    {
      title: 'How does FoodCoach determine the amounts of prepared food? ',
      text: 'FoodCoach relies on a cooking factor for these calculations. For example, the cooking factor for pasta is 2.4. This means that 100 grams of raw pasta will yield 240 grams of cooked pasta, as the pasta absorbs water during cooking. The nutritional values remain consistent for both 100 grams of raw pasta and 240 grams of cooked pasta, allowing you to easily scoop out 240 grams from the pan.',
    },
  ],
};

import buttons from './buttons.nl';
import counts from './counts.nl';
import headings from './headings.nl';
import info from './info.nl';
import labels from './labels.nl';
import links from './links.nl';
import modals from './modals.nl';
import networkErrors from './networkErrors.nl';
import notifications from './notifications.nl';
import placeholders from './placeholders.nl';
import progressBarExplanation from './progressBarExplanation.nl';
import titles from './titles.nl';
import preparedAmountsExplanation from './preparedAmountsExplanation.nl';

export default {
  buttons,
  counts,
  headings,
  info,
  labels,
  links,
  modals,
  networkErrors,
  notifications,
  placeholders,
  titles,
  progressBarExplanation,
  preparedAmountsExplanation,
};

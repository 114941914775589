import { useRoute } from 'vue-router/composables';
import { MomentKey } from '@blancofoodcoach/content-scaling';

export function useMoment(providedRoute = null) {
  const route = providedRoute || useRoute();
  const { moment: momentKeyString } = route.params;
  const momentKey = momentKeyString ? MomentKey.deserialize(momentKeyString) : undefined;

  return {
    moment: momentKey?.moment,
    momentKeyString,
    momentKey,
    trainingId: momentKey?.trainingId,
  };
}

export default {
  aboutThisApp: 'About <strong>this app</strong>',
  addOwnIngredient: 'Do you want to add your ingredient?',
  addOwnProduct: 'Do you want to add your product?',
  advancedFilters: {
    heading: '<strong>Filter</strong>',
    specialWishes: '<strong>Special wishes</strong>',
    dishType: '<strong>Dish type</strong>',
    popularFilter: '<strong>Popular filters</strong>',
    kitchen: '<strong>Kitchen</strong>',
  },
  buffet: '<strong>Buffet options</strong>',
  blogs: `Learn more in <strong>our blogs</strong>`,
  details: 'Data',
  eatingOnActivityList: 'Products to take during your training',
  emptyLockedMoment: 'This moment is empty',
  energyNeed: '<strong>My energy needs</strong>',
  myPlan: 'My plan',
  ftpValueExplanation: 'Learn more about FTP',
  graphExplanation: 'How do these bars work?',
  preparedAmountsExplanation: 'Raw vs Prepared amounts',
  macroNutrientsRatio: 'Macronutrient ratio',
  noMatchingIngredients: 'Is your ingredient not in this list?',
  noMatchingRecipes: 'No recipes found',
  noMatchingProducts: 'Is your product not in this list?',
  noSelectedProducts: "You haven't added any products yet",
  nutritionFacts: 'Nutrition facts',
  yourMeals: '<strong>My Meals</strong>',
  noRecipesCreated: "You didn't create own recipes yet",
  noRecipesCreatedForMealMoment: "You didn't create own recipes for this meal moment yet",
  createRecipeAction: 'Tune your favourite recipe into a performance meal',
  recipeCategories: {
    fast: 'Ready in <strong>{time} minutes</strong>',
    vegetarian: '<strong>A day without meats</strong>',
    vegan: '<strong>Vegan</strong>',
    bread: '<strong>Bread</strong> recepten',
    pasta: '<strong>Pasta</strong> recepten',
    oat: '<strong>Oatmeal</strong> recepten',
    rice: '<strong>Rice</strong> recepten',
    cottageCheese: '<strong>Quark</strong> recepten',
    vegetableRich: '<strong>Vegetables</strong> toppers',
    summer: '<strong>Summer meals</strong>',
    autumn: '<strong>Autumn meals</strong>',
    winter: '<strong>Winter meals</strong>',
    spring: '<strong>Spring meals</strong>',
    other: '<strong>Other</strong> recepten',
    classics: '<strong>Family meals</strong>',
    bakingTogether: '<strong>Baking</strong>',
    schijfVanVijf: '<strong>Schijf van Vijf</strong>',
  },
  scanBarcode: 'Scan <strong>barcode</strong> of product package',
  result: 'Result',
  saveTunedMoment: 'Save tuned {moment}',
  yourTrainings: '<strong>My training</strong>',
  thankYouForAddingThisIngredient: 'Thank you for adding this ingredient!',
  tuningSatisfaction: 'Are you satisfied with your meal?',
  yourMeal: 'Your meal',
  ingredientDetails: 'Product details',
  recentlyUsedProducts: 'Recently used',
  history: 'History',
  suggestions: 'Suggestions',
  cameraDenied: 'Camera access denied',
  unitOfMeasures: 'Units of measure',
};

export default {
  preparedAmountsExplanation: [
    {
      text: 'Door deze optie te gebruiken, kunt u de bereide hoeveelheden weergeven. FoodCoach past dan de kookfactor van een specifiek ingrediënt toe om te bepalen hoeveel u uit de pan moet scheppen.',
    },
    {
      title: 'Hoe bepaalt FoodCoach de hoeveelheden bereid voedsel?',
      text: 'FoodCoach vertrouwt op een kookfactor voor deze berekeningen. Bijvoorbeeld, de kookfactor voor pasta is 2,4. Dit betekent dat 100 gram rauwe pasta 240 gram gekookte pasta oplevert, omdat de pasta water absorbeert tijdens het koken. De voedingswaarden blijven consistent voor zowel 100 gram rauwe pasta als 240 gram gekookte pasta, zodat u gemakkelijk 240 gram uit de pan kunt scheppen.',
    },
  ],
};

import { definedArray } from '@blancofoodcoach/typescript-toolkit';
import {
  addDays,
  addWeeks,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay as fnsIsSameDay,
  parseISO,
  startOfMonth,
  startOfWeek,
  subWeeks,
} from 'date-fns';
import { ISO_DATE_FORMAT, WEEKDAY_FORMAT } from '@/modules/shared/constants/date.const';

export const createRange = (days = 14, start = new Date()) => {
  const dateTimes = eachDayOfInterval({
    start,
    end: addDays(start, days),
  });

  return dateTimes.map(dateTime => format(dateTime, ISO_DATE_FORMAT));
};

export const toISOString = date => format(date, ISO_DATE_FORMAT);

export const toWeekdayString = date => format(date, WEEKDAY_FORMAT);

export const nowISOString = () => toISOString(new Date());

export const isSameDay = (left, right) => {
  const leftDate = !left ? new Date() : parseISO(left);
  const rightDate = !right ? new Date() : parseISO(right);

  return fnsIsSameDay(leftDate, rightDate);
};

export const generateMonthArray = (date = new Date()) => {
  const start = startOfMonth(date);
  const end = endOfMonth(date);
  const dates = eachDayOfInterval({ start, end });
  const datesPerWeek = [];

  while (dates.length) {
    datesPerWeek.push(dates.splice(0, 7));
  }

  return datesPerWeek;
};

export const generateWeekRangeAroundDate = (date = new Date(), weeks = 2) => {
  const start = startOfWeek(subWeeks(date, weeks));
  const end = endOfWeek(addWeeks(date, weeks));
  const dates = eachDayOfInterval({ start, end });
  const datesPerWeek = [];

  while (dates.length) {
    datesPerWeek.push(dates.splice(0, 7));
  }

  return datesPerWeek;
};

export const findWeekIndex = (datesPerWeek, dateToFind) =>
  definedArray(datesPerWeek)
    .orEmpty()
    .findIndex(dates =>
      definedArray(dates)
        .orEmpty()
        .some(date => fnsIsSameDay(date, dateToFind))
    );

export default {
  mealMoments: {
    breakfast: '{n} ontbijtrecepten | {n} ontbijtrecept | {n} ontbijtrecepten',
    morningSnack: '{n} ochtendsnacks | {n} ochtendsnack | {n} ochtendsnacks',
    preRaceMeal: '{n} pre-race meals | {n} pre-race meal | {n} pre-race meals',
    preRaceSnack: '{n} pre-race snacks | {n} pre-race snack | {n} pre-race snacks',
    lunch: '{n} lunchrecepten | {n} lunchrecept | {n} lunchrecepten',
    afternoonSnack: '{n} middagsnacks | {n} middagsnack | {n} middagsnacks',
    recoverySnack: '{n} shakes | {n} shake | {n} shakes',
    recoveryMeal1: '{n} herstelrecepten | {n} herstelrecept | {n} herstelrecepten',
    recoveryMeal2: '{n} herstelrecepten | {n} herstelrecept | {n} herstelrecepten',
    dinner: '{n} dinerrecepten | {n} dinerrecept | {n} dinerrecepten',
    eveningSnack: '{n} avondsnacks | {n} avondsnack | {n} avondsnacks',
  },
  badges: {
    kcal: '{n} kcal',
    carbs: 'Koolh. {n} g',
  },
  progress: {
    kcal: 'Kcal: {planned}/{target}',
    carbs: 'Koolhydraten: {planned}/{target} g',
    short: {
      kcal: '({planned}/{target} kcal)',
    },
  },
  min: '{n} min.',
  ingredients: '{n} ingrediënten | {n} ingrediënt | {n} ingrediënten',
  ingr: '{n} ingr.',
};
